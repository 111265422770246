import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fetchStates } from '@src/utility/Api'

export const EstimativeContext = createContext()

const defaultValuesAddress = {
  newDealership: true,
  id: '',
  name: '',
  document: '',
  responsible_name: '',
  responsible_document: '',
  brand: '',
  address: {
    id: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    zipcode: '',
    state: '',
    city: ''
  }
}

function EstimativeContextProvider({ children }) {
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedBrand, setSelectedBrand] = useState({
    firstBrandSelected: { id: '', name: '', description: '', logo: '' },
    secondBrandSelected: { id: '', name: '', description: '', logo: '' },
    thirdBrandSelected: { id: '', name: '', description: '', logo: '' }
  })
  const [numberVehiclesPerMonth, setNumberVehiclesPerMonth] = useState({
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: ''
  })
  const [addressSelectedBrandDealerships, setAddressSelectedBrandDealerships] = useState({
    firstBrandSelected: defaultValuesAddress,
    secondBrandSelected: defaultValuesAddress,
    thirdBrandSelected: defaultValuesAddress
  })
  const [month, setMonth] = useState('') 
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    const fetchStatesApi = async () => {
      const response = await fetchStates()

      setStates(response.data)
    }

    fetchStatesApi()
  }, [])

  const context = {
    selectedCategory,
    setSelectedCategory,
    selectedBrand,
    setSelectedBrand,
    numberVehiclesPerMonth,
    setNumberVehiclesPerMonth,
    addressSelectedBrandDealerships,
    setAddressSelectedBrandDealerships,
    defaultValuesAddress,
    month,
    setMonth,
    states,
    cities,
    setCities
  }

  return  (
    <EstimativeContext.Provider value={context}>
      {children}
    </EstimativeContext.Provider>
  )
}

EstimativeContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default EstimativeContextProvider
