import { combineReducers } from 'redux'
// ** Reducers Imports
// ** IMPORTAÇÕES NATIVAS DO VUEXY, VOU DEIXAR AQUI PARA APAGAR CADA ARQUIVO DEPOIS (comentário)
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
//** IMPORTAÇÕES DO PROJETO PORTAL-DE-COMPRAS= 
import AdminDashboardReducer from './reducers/AdminDashboardReducer'
import BrandsReducer from './reducers/BrandsReducer'
import CategoriesReducer from './reducers/CategoriesReducer'
import ColorsReducer from './reducers/ColorsReducer'
import DealershipReducer from './reducers/DealershipReducer'
import EstimativeReducer from './reducers/EstimativeReducer'
import OrdersReducer from './reducers/OrdersReducer'
import RentalDashboardReducer from './reducers/RentalDashboardReducer'
import RentalsReducer from './reducers/RentalsReducer'
import UsersReducer from './reducers/UsersReducer'
import VehiclesReducer from './reducers/VehiclesReducer'


const rootReducer = combineReducers({
  auth,
  navbar,
  layout,

  //** STORES PROJETO DE COMPRAS (comentário) */
  admindashboard: AdminDashboardReducer,
  brands: BrandsReducer,
  categories: CategoriesReducer,
  colors: ColorsReducer,
  dealership: DealershipReducer,
  estimative: EstimativeReducer,
  orders: OrdersReducer,
  rentaldashboard: RentalDashboardReducer,
  rentals: RentalsReducer,
  users: UsersReducer,
  vehicles: VehiclesReducer
})

export default rootReducer
