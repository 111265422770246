const initialState = {
    data: [],
    isUpdated: false
}

const AdminDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ADMINDASH': {
            return {...state,
                data: action.data,
                isUpdated: true
            }
        }
        default:
            return state
        
    }
    
}

export default AdminDashboardReducer