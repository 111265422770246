import axios from 'axios'
const api_url = 'https://api.portaldecompras.abla.com.br'

export const fetchLogin = async (username, password, group) => {
  const response = await fetch(`${api_url}/token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password,
      group
    })
  }).then((res) => res.json())
    // console.log(response)
  return response
}

export const fetchRefreshToken = async (refresh) => {
  const response = await fetch(`${api_url}/token/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      refresh
    })
  }).then((res) => res.json())
  return response
}
export const fetchUser = async (token) => {
  const response = await fetch(`${api_url}/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify()
  }).then((res) => res.json())
  // console.log(response)
  return response
}

export const fetchStates = async (name = '', acronym = '') => {
  try {
    const response = await axios.get(`${api_url}/state/?name=${name}&acronym=${acronym}`)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const fetchCities = async (stateId = '', name = '') => {
  try {
    const response = await axios.get(`${api_url}/city/?state=${stateId}&name=${name}`)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const fetchBrands = async () => {
  try {
    const response = await axios.get(`${api_url}/brand/`)
    return response
  } catch (error) {
    console.log(error)
  }
}