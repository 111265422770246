// ** Icons Import
import { useState } from 'react'
import { Coffee } from 'react-feather'
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

const Footer = () => {
  const [show, setShow] = useState(false)

  return (
    <p className='clearfix mb-0'>
      <span className='float-md-left d-block d-md-inline-block mt-25'>
        Portal de Compras <a onClick={() => {
          throw new Error('Error Sentry')
        }}>-</a> Versão 1.0.1 - Made by AblaOne. Todos os Direitos Reservados @2022. Uma empresa da Associação Brasileira das Locadoras de Automóveis - <a href='https://abla.com.br/' target='_blank' rel='noopener noreferrer'>
          ABLA</a>.
        <a onClick={() => setShow(true)}> Política de Privacidade.</a>
        <Modal isOpen={show} toggle={() => setShow(!show)} className='modal-dialog-centered modal-lg'>
          <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
          <ModalBody className='px-sm-5 mx-50 pb-5'>
            <div className='text-center mb-2'>
              <h1 className='mb-1'>Política de Privacidade</h1>
            </div>
            <Row tag='form' className='gy-1 pt-75'>
              <Col md={12} xs={12}>
                <Label className='form-label' for='description'>
                  Nós temos uma parceria com a Microsoft Clarity e a Microsoft Advertising para entender como você utiliza e interage com nosso site. Utilizamos métricas comportamentais, mapas de calor e reprodução de sessões para melhorar e divulgar nossos produtos e serviços. Coletamos dados de uso do site através de cookies de primeira e terceira partes e outras tecnologias de rastreamento para avaliar a popularidade dos nossos produtos/serviços e atividades online. Além disso, usamos essas informações para otimização do site, segurança e prevenção de fraudes. Para mais informações sobre como a Microsoft coleta e usa seus dados, visite o site <a href='https://privacy.microsoft.com/privacystatement'>https://privacy.microsoft.com/privacystatement</a>.          </Label>
              </Col>

              <Col xs={12} className='text-center mt-2 pt-50'>

                <Button type='reset' color='light' onClick={() => setShow(false)}>
                  Fechar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </span>

    </p>
  )
}

export default Footer
