const initialState = {
    data: [],
    selectedCategory: null
}

const CategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_CATEGORY': {
            return {
                ...state,
                data: action.data
            }
        }
        case 'GET_CATEGORIES': {
            return {
                ...state,
                data: action.data
            }
        }
        default:
            return state

    }
}

export default CategoriesReducer