/* eslint-disable */
import { lazy } from 'react'
import EstimativeContextProvider from '../../contexts/EstimativeContext'

const ManagementDashboard = lazy(() =>
  import('../../views/management-admin/admin-dashboard')
)
const ManagementCrudRental = lazy(() =>
  import('../../views/management-admin/admin-crud-rental')
)
const ManagementCrudUsers = lazy(() =>
  import('../../views/management-admin/admin-crud-users')
)
const ManagementOrdersBilling = lazy(() =>
  import('../../views/management-admin/admin-billing-orders')
)
const ManagementOrders = lazy(() =>
  import('../../views/management-admin/admin-orders')
)

const ManagementMonthlyOrders = lazy(() =>
  import('../../views/management-admin/admin-orders/monthly-orders')
)
const ManagementCompanies = lazy(() =>
  import('../../views/management-admin/admin-companies')
)
const ManagementDealerships = lazy(() =>
  import('../../views/management-admin/admin-dealerships')
)
const ManagementCategories = lazy(() =>
  import('../../views/management-admin/admin-category')
)
const ManagementVehicles = lazy(() =>
  import('../../views/management-admin/admin-cars')
)
const ManagementEstimates = lazy(() =>
  import('../../views/management-admin/admin-estimates')
)
// const ManagementMonthlyEstimates = lazy(() =>
//   import('../../views/management-admin/admin-estimates/monthly-estimates')
// )
const ManagementEstimatesBilling = lazy(() =>
  import('../../views/management-admin/admin-billing-estimates')
)

const ManagementMonthlyEstimates = lazy(() =>
  import(
    '../../views/management-admin/admin-estimates/monthly-estimates/index'
  )
)
const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

const estimates = months.map((month) => {
  return {
    path: `/management-admin/monthly-estimates/${month}`,
    element: <ManagementMonthlyEstimates />,
    resource: ['super_admin']
  }
})
const orders = months.map((month) => {
  return {
    path: `/management-admin/monthly-orders/${month}`,
    element: <ManagementMonthlyOrders />,
    resource: ['super_admin']
  }
})
const ManagementAdminRoutes = [
  {
    path: '/management-admin/home',
    element: <ManagementDashboard />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/companies',
    element: <ManagementCompanies />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/categories',
    element: <ManagementCategories />,
    resource: ['super_admin']
  },
   {
    path: '/management-admin/dealerships',
    element: <ManagementDealerships />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/estimates',
    element: <ManagementEstimates />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/vehicles',
    element: <ManagementVehicles />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/rental',
    element: <ManagementCrudRental />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/users',
    element: <ManagementCrudUsers />,
    resource: ['super_admin']
  },

  {
    path: '/management-admin/orders-billing',
    element: <ManagementOrdersBilling />,
    resource: ['super_admin']
  },

  {
    path: '/management-admin/estimates-billing',
    element: <ManagementEstimatesBilling />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/orders',
    element: <ManagementOrders />,
    resource: ['super_admin']
  },
  {
    path: '/management-admin/monthly-orders',
    element: <ManagementMonthlyOrders />,
    resource: ['super_admin']
  },
 
  ...estimates,
  ...orders
]

export default ManagementAdminRoutes
