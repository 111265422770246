// ** Icons Import
import { Home, Circle, DollarSign, Clipboard, UserCheck, Edit, FileText, Lock } from 'react-feather'

export default [
  {
    header: 'Gerenciamento',
    resource: 'gestor'
  },
  {
    id: 'Gerenciamento Dashboard',
    title: 'Dashboard',
    icon: <Home size={20} />,
    navLink: '/management-user/home',
    resource: 'gestor'
  },
  {
    id: 'Gerenciamento Intenção',
    title: 'Intenção de Compra',
    icon: <Edit size={20} />,
    children: [
      {
        id: 'Intenção de Compra',
        title: 'Enviar intenção',
        disabled: true,
        icon: <Lock size={12} />,
        navLink: '/management-user/send-estimate',
        meta: {
          className: 'ecommerce-application'
        },
        resource: 'gestor'
      },
      {
        id: 'Intenções enviadas',
        title: 'Intenções enviadas',
        icon: <Edit size={12} />,
        navLink: '/management-user/estimates',
        resource: 'gestor'
      }
    ],
    resource: 'gestor'
  },
  {
    id: 'Gerenciamento Pedidos',
    title: 'Pedidos',
    icon: <Clipboard size={20} />,
    children: [
      {
        id: 'Enviar Pedido',
        title: 'Enviar Pedido',
        disabled: true,
        icon: <Lock size={12} />,
        navLink: '/management-user/send-order',
        meta: {
          className: 'ecommerce-application'
        },
        resource: 'gestor'
      },
      {
        id: 'Pedidos enviados',
        title: 'Pedidos enviados',
        //icon: <Clipboard size={12} />,
        icon: <Clipboard size={12}  />,
        navLink: '/management-user/orders',
        resource: 'gestor'
      }
    ],
    resource: 'gestor'
  },
  {
    id: 'Gerenciamento Faturamento',
    title: 'Faturamento',
    icon: <DollarSign size={20} />,
    children: [
      {
        id: 'Faturamento Intencao',
        title: 'Intenções',
        icon: <DollarSign size={12} />,
        navLink: '/management-user/billing/estimate',
        resource: 'gestor'
      }
      // {
      //   id: 'Faturamento Pedido',
      //   title: 'Pedidos',
      //   icon: <DollarSign size={12} />,
      //   navLink: '/management-user/billing/order',
      //   resource: 'gestor'
      // }
    ],
    resource: 'gestor'
  },
  {
    header: 'Gestão',
    resource: 'super_admin'
  },
  {
    id: 'Gestão Dashboard',
    title: 'Dashboard',
    icon: <Home size={20} />,
    navLink: '/management-admin/home',
    resource: 'super_admin'
  },
  {
    id: 'Gestão Locadoras',
    title: 'Locadoras',
    icon: <UserCheck size={20} />,
    children: [
      {
        id: 'Locadoras',
        title: 'Gestão de Locadoras',
        icon: <UserCheck size={12} />,
        navLink: '/management-admin/rental',
        resource: 'super_admin'
      },
      {
        id: 'Usuarios',
        title: 'Gestão de Usuários',
        icon: <UserCheck size={12} />,
        navLink: '/management-admin/users',
        resource: 'super_admin'
      }
    ],
    resource: 'super_admin'
  },
  {
    id: 'Montadoras',
    title: 'Montadoras',
    icon: <FileText size={20} />,
    children: [
      {
        id: 'Gestão de Montadoras',
        title: 'Montadoras',
        icon: <FileText size={12} />,
        navLink: '/management-admin/companies',
        resource: 'super_admin'
      },
      {
        id: 'Gestão de Categorias',
        title: 'Categorias',
        icon: <FileText size={12} />,
        navLink: '/management-admin/categories',
        resource: 'super_admin'
      },
      {
        id: 'Gestão de Veículos',
        title: 'Veículos',
        icon: <FileText size={12} />,
        navLink: '/management-admin/vehicles',
        resource: 'super_admin'
      },
      {
        id: 'Gestão de Concessionárias',
        title: 'Concessionárias',
        icon: <FileText size={12} />,
        navLink: '/management-admin/dealerships',
        resource: 'super_admin'
      }
      
    ],
    resource: 'super_admin'
  },
  {
    id: 'Gestão Intenções',
    title: 'Intenções recebidas',
    icon: <Edit size={20} />,
    navLink: '/management-admin/estimates',
    resource: 'super_admin'
  },
  {
    id: 'Gestão Pedidos',
    title: 'Pedidos recebidos',
    icon: <Clipboard size={20} />,
    navLink: '/management-admin/orders',
    resource: 'super_admin'
  },
  {
    id: 'Gestão Faturamento',
    title: 'Faturamentos',
    icon: <DollarSign size={20} />,
    children: [
      {
        id: 'Faturamento Intenções',
        title: 'Intenções',
        icon: <DollarSign size={12} />,
        navLink: '/management-admin/estimates-billing',
        resource: 'super_admin'
      }
      // {
      //   id: 'Faturamento Pedidos',
      //   title: 'Pedidos',
      //   icon: <DollarSign size={12} />,
      //   navLink: '/management-admin/orders-billing',
      //   resource: 'super_admin'
      // }
    ],
    resource: 'super_admin'
  }
]
