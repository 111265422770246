const initialState = {
    data: []
}

const BrandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BRANDS': {
            return {...state,
                data: action.data
            }
        }
        default:
            return state
        
    }
    
}

export default BrandsReducer
