const initialState = {
    data: []
  }
  
  const UsersReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_USERS': {
      return {...state,
        data: action.data
      }    
    }
      case 'DELETE_INVOICE':
        return { ...state }
      default:
        return { ...state }
    }
  }
  export default UsersReducer