const initialState = {
  data: [],
  loading: false
}

const DealershipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_DEALERSHIP': {
      return {
        ...state,
        data: [...state.data, action.data]
      }
    }
    case 'GET_DEALERSHIP': {
      return {
        ...state,
        data: action.data
      }
    }
    case 'LOADING_DEALERSHIP': {
      return {
        ...state,
        loading: action.loading
      }
    }
      default:
        return state
  }
}

export default DealershipsReducer