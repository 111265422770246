const initialState = {
    data: []
}

const EstimativeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ESTIMATES': {
            return {...state,
                data: action.data
            }
        }
        default:
            return state
        
    }
    
}

export default EstimativeReducer