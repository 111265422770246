import * as Sentry from '@sentry/react'
import { getUserData } from './utility/Utils'

Sentry.init({
    dsn: "https://eaa61fd502fb7d88581e592256659c4f@o4505624290131968.ingest.us.sentry.io/4507296733528064",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ]
})

function setUser(user) {
    Sentry.setUser({
        email: user.email,
        username: user.username,
        rentalCompany: user.rentalCompany,
        fullName: user.fullName,
        role: user.role
    })
}

const user = getUserData()

if (user !== null) {
    setUser(user)
}