const initialState = {
    data: []
}
  
const VehiclesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_VEHICLE': {
            return {...state,
                data: action.data
            }
        }
        case 'GET_VEHICLES': {
            return {...state,
                data: action.data
            }
        }
        default:
            return state
        
    }
}

export default VehiclesReducer