import { lazy } from 'react'

const UserDashboard = lazy(() => import('../../views/management-user/user-dashboard'))
const UserEstimateBilling = lazy(() => import('../../views/management-user/user-estimates-billing'))
const UserOrderBilling = lazy(() => import('../../views/management-user/user-orders-billing'))
const UserOrders = lazy(() => import('../../views/management-user/user-orders'))
const UserSendOrder = lazy(() => import('../../views/management-user/user-send-order'))
const UserEstimates = lazy(() => import('../../views/management-user/user-estimates'))
const UserSendEstimate = lazy(() => import('../../views/management-user/user-send-estimate'))
//pages form wizard
const UserOrderConcluded = lazy(() => import('../../views/management-user/user-send-order/send-order-concluded'))
const UserEstimateConcluded = lazy(() => import('../../views/management-user/user-send-estimate/send-estimate-concluded'))
const UserStepCompany = lazy(() => import('../../views/management-user/user-send-estimate/steps/step-company'))
const UserStepAddress = lazy(() => import('../../views/management-user/user-send-order/steps/step-address'))
const UserStepTerm = lazy(() => import('../../views/management-user/user-send-order/steps/step-term'))

const ManagementUserRoutes = [
  {
    path: '/management-user/home',
    element: <UserDashboard />,
    resource: ['gestor', 'super_admin']
  },
  {
    path: '/management-user/orders',
    element: <UserOrders />,
    resource: ['colaborador', 'gestor', 'super_admin']
  },
  {
    path: '/management-user/send-order/address',
    element: <UserStepAddress />,
    meta: {
      className: 'ecommerce-application'
    }
  },
  // {
  //   path: '/management-user/send-order',
  //   element: <UserSendOrder />,
  //   meta: {
  //     className: 'ecommerce-application'
  //   },
  //   resource: ['colaborador', 'gestor', 'super_admin']
  // },
  {
    path: '/management-user/send-order/term',
    element: <UserStepTerm />,
    resource: ['colaborador', 'gestor', 'super_admin']
  },
  {
    path: '/management-user/send-order/concluded',
    element: <UserOrderConcluded />,
    resource: ['colaborador', 'gestor', 'super_admin']
  },
  {
    path: '/management-user/estimates',
    element: <UserEstimates />,
    resource: ['colaborador', 'gestor', 'super_admin']
  },
  // {
  //   path: '/management-user/send-estimate',
  //   element: <UserSendEstimate />,
  //   meta: {
  //     className: 'ecommerce-application'
  //   },
  //   resource: ['colaborador', 'gestor', 'super_admin']
  // },
  {
    path: '/management-user/send-estimate/company',
    element: <UserStepCompany />,
    resource: ['colaborador', 'gestor', 'super_admin']
  },
  {
    path: '/management-user/send-estimate/concluded',
    element: <UserEstimateConcluded />,
    resource: ['colaborador', 'gestor', 'super_admin']
  },
  // {
  //   path: '/management-user/billing/order',
  //   element: <UserOrderBilling />,
  //   resource: ['gestor', 'super_admin']
  // },
  {
    path: '/management-user/billing/estimate',
    element: <UserEstimateBilling />,
    resource: ['gestor', 'super_admin']
  }
]

export default ManagementUserRoutes
